import { graphql } from 'gatsby'
import Collection from '../containers/Collection.Author'

export default Collection

export const pageQuery = graphql`
  query allArticleByAuthorQuery($slug: String!) {
    collectionInfo: strapiAuthor(slug: { eq: $slug }) {
      id
      name
      slug
      title
      authorDescription {
        data {
          authorDescription
        }
      }
      social {
        name
        url
      }
    }

    seo: strapiAuthor(slug: { eq: $slug }) {
      id
      seo {
        metaDescription
        keywords
        metaTitle
      }
    }

    videos: allStrapiVideo(
      filter: { authors: { elemMatch: { slug: { eq: $slug } } } }
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        id
        title
        authors {
          id
          name
          title
          slug
          updatedAt
          social {
            name
            url
          }
        }
      }
    }
    blogs: allStrapiBlog(
      filter: { authors: { elemMatch: { slug: { eq: $slug } } } }
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        id
        title
        slug
        categories {
          name
          slug
        }
        excerpt {
          data {
            excerpt
          }
        }
        body {
          data {
            body
          }
        }
      }
    }
  }
`
