import React from 'react'
import { Layout, Stack, Main, PreFooter } from '@layout'
import PageTitle from '@components/PageTitle'
import Pagination from '@components/Pagination'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import AuthorExpanded from '@widgets/AuthorExpanded'
import { usePostExcerpt } from '@helpers-blog'
import {
  attachPostDetails,
  attachAuthorDetails,
  attachCategoriesToEvent,
  attachExcerptToEvent
} from '@utils-blog'

const PageCollectionAuthors = ({
  data: { videos, blogs, collectionInfo, seo },
  ...props
}) => {
  if (blogs.nodes && blogs.nodes.length > 0) {
    blogs.nodes.forEach(item => {
      item.bodyContent = usePostExcerpt(item.title)
      item.excerpt = item.bodyContent
    })
  }
  if (videos.nodes && videos.nodes.length > 0) {
    videos.nodes.forEach(item => {
      item.excerpt = usePostExcerpt(item.title)
      item.bodyContent = usePostExcerpt(item.title)
    })
  }

  const authorSeoData = {
    ...seo,
    seo: { ...seo?.seo, keywords: seo?.seo?.keywords?.split('\n') }
  }

  attachAuthorDetails(collectionInfo)
  // attachCategoriesToEvent(videos)
  // attachCategoriesToEvent(blogs)
  blogs.nodes = addKey(blogs.nodes, 'Blog')
  // posts.nodes = addKey(posts.nodes, 'Post')
  videos.nodes = addKey(videos.nodes, 'Video')

  const data = [...videos.nodes, ...blogs.nodes]
  const item = groupBy([...data], c => c.belongTo)
  function groupBy(xs, f) {
    return xs.reduce(
      (r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r),
      {}
    )
  }

  function addKey(list, key) {
    list.forEach(item => {
      item.belongTo = key
    })
    return list
  }

  const object = { nodes: data }
  attachPostDetails(object) // Attach missing post details
  return (
    <Layout {...props}>
      <Seo
        title={authorSeoData?.seo?.metaTitle}
        description={authorSeoData?.seo?.metaDescription}
        keywords={authorSeoData?.seo?.keywords}
        thumbnail={{
          url: 'https://newmanreview-assets.s3.ap-south-1.amazonaws.com/syposium_4bf3fb68e1.jpg'
        }}
      />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle header='Published by Author' totalCount={data.length} />
      </Stack>
      <Divider />
      <Stack>
        <Main>
          <AuthorExpanded author={collectionInfo} />
          <Divider />
          {Object.keys(item).map(key => {
            return (
              <Stack title={key}>
                <Main>
                  {item[key] && (
                    <>
                      <CardList
                        nodes={item[key]}
                        variant={['horizontal-md', 'vertical']}
                        columns={[1, 2, 3, 3]}
                        omitCategory
                        callFrom='author'
                      />
                      <Divider />
                    </>
                  )}
                </Main>
                <Divider />
              </Stack>
            )
          })}
        </Main>
      </Stack>
      <Divider />
      {/* <PreFooter>
        <Pagination
          {...pageInfo}
          {...collectionInfo}
          {...{ slug: `/author/${collectionInfo.slug}/` }}
        />
      </PreFooter> */}
    </Layout>
  )
}

export default PageCollectionAuthors
